.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-container {
  width: 100%; 
  text-align: right
}
.text-center {
  text-align: right
}
.margin5{
  margin: 5px
}
.hide{
  display: none;
}
.show{
  display: block;
}

.download-button {
  text-align: center;
}

.form-body-container {
  max-width: 800px;
  width: 90%;
  margin: auto;
}

.login-button-container {
  text-align: center;
}

.login-button-title {
  color: #006a4d;
}

.login-button-style {
  border: none;
  background-color:#006A4D;
  color: #fff;
  padding: 10px 35px;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 15px;
}

.member-login-container {
  border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10% auto;
    text-align: center;
    padding: 30px;
    width: 450px;
}
.topline{width:100%;height:2px;background:#4FC6E1;margin:20px 0;}
:root {
  --amplify-primary-color: #003f2d;
  --amplify-primary-tint: #003f2b;
  --amplify-primary-shade: #003f2d;
}
.notice{text-align: left;}
.notice h5{margin: 5px auto;}
.notice span{font-weight: bolder;text-decoration: underline;}